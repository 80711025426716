import React from "react"
import { PageRendererProps, graphql, Link } from "gatsby"
import { ContentfulPostsQuery } from "types/graphql-types"
import styled from "styled-components"
import dayjs from "dayjs"
import SEO from "@/components/SEO"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarAlt, faRedoAlt } from "@fortawesome/free-solid-svg-icons"

type PostsPageProps = PageRendererProps & {
  data: ContentfulPostsQuery
}

const title = 'ZEN Architects 記事一覧'
const description = 'ZEN Architects 発信のお知らせや記事の一覧です'

const PostsPage: React.FC<PostsPageProps> = ({ data, location }) => {
  const posts = data.allContentfulPost.edges.map(e => e.node)

  return (
    <>
      <SEO title={title} description={description} pathname={location.pathname} />
      <Root>
        <div className="posts">
          {posts.map(post => (
            <Link key={post.id} to={`/posts/${post.slug}`} className="post">
              {post.eyecatch && (
                <img
                  className="eyecatch"
                  src={
                    post.eyecatch.file?.url
                      ? `${post.eyecatch.file?.url}?w=600`
                      : undefined
                  }
                />
              )}
              <div className="content">
                <div className="title">{post.title}</div>
                {post.tags?.length && (
                  <div className="tags">
                    {post.tags.map((tag, index) => (
                      <span key={index} className="tag">
                        {tag}
                      </span>
                    ))}
                  </div>
                )}
                <div className="body">
                  {post.body?.childMarkdownRemark?.excerpt}
                </div>
                <div className="dates">
                  <div className="date">
                    <span className="icon">
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </span>
                    {formatDate(post.createdAt)}
                  </div>
                  <div className="date">
                    <span className="icon">
                      <FontAwesomeIcon icon={faRedoAlt} />
                    </span>
                    {formatDate(post.updatedAt)}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </Root>
    </>
  )
}

function formatDate(date: Date): string {
  return dayjs(date).format('YYYY/MM/DD')
}

export const query = graphql`
  query contentfulPosts {
    allContentfulPost(sort: {fields: createdAt, order: DESC}) {
      edges {
        node {
          id
          slug
          title
          tags
          body {
            childMarkdownRemark {
              excerpt(truncate: true, pruneLength: 120)
            }
          }
          eyecatch {
            file {
              url
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`

const Root = styled.div`
  background-color: ${(props) => props.theme.secondaryLightColor};
  padding: 50px 0 0;
  min-height: calc(100vh - 400px);

  .posts {
    margin: 0 auto;
  }

  .post {
    background-color: #fff;
    display: block;
    text-decoration: none;
    color: ${(props) => props.theme.textColor};
    padding: 30px;
    display: flex;
    flex-direction: column;
  }

  .post + .post {
    margin-top: 15px;
  }

  .post .eyecatch {
    width: 100%;
    height: 200px;
    margin-bottom: 20px;
    object-fit: contain;
    object-position: top center;
  }

  .post .title {
    font-weight: 600;
    font-size: 1.25rem;
    margin-bottom: 1rem;
    text-align: justify;
  }

  .post .tags {
    margin: -0.5rem -0.25rem 0.75rem;
    display: flex;
    flex-wrap: wrap;
  }

  .post .tag {
    background-color: ${(props) => props.theme.secondaryLightColor};
    padding: 4px 0.5rem;
    border-radius: 2px;
    font-size: 0.8rem;
    margin: 0.25rem;
  }

  .post .body {
    line-height: 1.5;
    color: ${(props) => props.theme.secondaryDarkColor};
    font-size: 0.9rem;
    text-align: justify;
  }

  .post .dates {
    display: flex;
    margin-top: 0.75rem;
  }

  .post .date {
    display: flex;
    align-items: center;
    font-size: 0.8rem;
  }

  .post .date .icon {
    color: ${(props) => props.theme.primaryColor};
    margin-right: 0.5rem;
    font-size: 0.9rem;
  }

  .post .dates .date + .date {
    margin-left: 0.75rem;
  }

  @media ${(props) => props.theme.minMdUp} {
    padding: 110px 0 30px;
    min-height: calc(100vh - 200px);

    .posts {
      width: 750px;
    }

    .post {
      flex-direction: row;
      border-radius: 2px;
    }

    .post {
      transition: opacity .2s;
    }

    .post:hover {
      opacity: 0.8;
    }

    .post .eyecatch {
      width: 200px;
      height: auto;
      margin-bottom: 0;
      margin-right: 30px;
      border-radius: 2px;
    }
  }

  @media ${(props) => props.theme.minLgUp} {
    .posts {
      width: 970px;
    }
  }
`

export default PostsPage
